<template>
  <div></div>
</template>

<script>
// 这里可以导入其他文件（比如：组件，工具 js，第三方插件 js，json 文件，图片文件等等）
// 例如：import  《组件名称》  from '《组件路径》 ';
import {
  ref,
  reactive,
  onBeforeMount,
  onMounted,
  onBeforeUpdate,
  onUpdated,
  onBeforeUnmount,
  onUnmounted,
  onErrorCaptured,
  onRenderTracked,
  onRenderTriggered,
  onActivated,
  onDeactivated
} from 'vue'

export default {
  name: 'test',
  // import 引入的组件需要注入到对象中才能使用
  components: {},
  // 组件支柱
  props: {},
  // 计算属性 类似于 data 概念
  computed: {},
  // 监控 data 中的数据变化
  watch: {},
  // setup在beforeCreate之前执行一次，this是undefined。
  setup() {
    // 生命周期 - 挂载之前
    onBeforeMount(() => {
    })
    // 生命周期 - 挂载完成（可以访问 DOM 元素）
    onMounted(() => {
    })
    // 生命周期 - 更新之前
    onBeforeUpdate(() => {
    })
    // 生命周期 - 更新之后
    onUpdated(() => {
    })
    // 生命周期 - 销毁之前
    onBeforeUnmount(() => {
    })
    // 生命周期 - 销毁完成
    onUnmounted(() => {
    })
    // 当事件处理程序或生命周期钩子抛出错误时
    onErrorCaptured((err) => {
    })
    // 渲染的时候可以追踪到
    onRenderTracked((e) => {
    })
    // 重新渲染的时候触发
    onRenderTriggered((e) => {
    })
    // 如果页面有 keep-alive 缓存功能,这个两个函数会触发
    // 进入的时候触发
    onActivated(() => {
    })
    // 离开的时候触发
    onDeactivated(() => {
    })
    return {
      //组件中使用的变量和绑定的方法都需要return才能使用
    }
  },
  // 方法集合
  methods: {}
}
</script>

<style scoped>
</style>
